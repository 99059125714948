export function numberPositiveIntegerOnly (e) {
  // List of allowed keys: number keys, arrow keys, backspace, and delete
  const allowedKeys = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
    'Backspace', 'Delete'
  ]

  // Prevent default action if the key pressed is not in the allowedKeys list
  if (!allowedKeys.includes(e.key)) {
    e.preventDefault()
    return false
  }

  // Optional: Prevent input of '0' if it's the first character in the input field (for integer inputs)
  if (e.target.value.length === 0 && e.key === '0') {
    e.preventDefault()
    return false
  }
}

export function decimalInputOnly (e) {
  const allowedKeys = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
    'Backspace', 'Delete', '.'
  ]

  // Allow all navigation and deletion keys regardless of the current input value
  if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Delete'].includes(e.key)) {
    return true // Allow these keys without any further checks
  }

  // Prevent default action if the key pressed is not in the allowedKeys list
  if (!allowedKeys.includes(e.key)) {
    e.preventDefault()
    return false
  }

  // Prevent multiple decimal points
  if (e.key === '.' && e.target.value.includes('.')) {
    e.preventDefault()
    return false
  }

  // Calculate what the new value would be if this keypress is allowed
  const proposedValue = e.target.value + e.key

  // Optional: Prevent leading '0' if it's not followed by a decimal point
  if (proposedValue === '0' && e.key !== '.' && !e.target.value.includes('.')) {
    e.preventDefault()
    return false
  }
}

export function filterByDate (itemDate, startDate, endDate) {
  const start = startDate ? new Date(startDate) : null
  const end = endDate ? new Date(endDate) : null
  const current = new Date(itemDate)

  if (start && end) {
    end.setHours(23, 59, 59, 999)
    return current >= start && current <= end
  } else if (start) {
    return current >= start
  } else if (end) {
    end.setHours(23, 59, 59, 999)
    return current <= end
  }
  return true
}
export function formatIOSDate (dateString) {
  const date = new Date(dateString)
  const formattedDate = date.toLocaleString('cn-HK', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })
  return formattedDate
}
